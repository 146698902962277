import React, { useState } from "react";
import { auth, db } from "./firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import "./tailwind.css";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Login({ user }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password === "unique1234") {
      try {
        await sendPasswordResetEmail(auth, email);
        setError(
          "Since you are attempting to log in with the default password, we have sent you an email to guide you through changing it to ensure your account's security."
        );
      } catch (sendPasswordResetError) {
        console.error("Password reset error:", sendPasswordResetError);
        setError(
          "There was an issue sending the password reset email. Please try again later."
        );
      } finally {
        setLoading(false);
      }
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log(userData.status);
        if (userData.status === "terminated") {
          alert(
            "Your account is not active. Please check your status with your supervisor."
          );
          await auth.signOut();
          setLoading(false);
          return;
        }

        if (
          userData.hasSignedNonCompete === false ||
          userData.hasSignedNonCompete === undefined
        ) {
          navigate("/non-compete-agreement");
        } else {
          const role = userData.role;
          const applicationComplete = userData.applicationComplete;

          if (
            role === "owner" ||
            role === "manager" ||
            role === "admin" ||
            role === "director" ||
            role === "supervisor"
          ) {
            // Set firstName in local storage and pass it to the dashboard
            localStorage.setItem("userFirstName", userData.firstName);
            navigate("/dashboard", {
              state: { firstName: userData.firstName },
            });
          } else if (role === "employee" || role === "mobile") {
            if (applicationComplete === false) {
              navigate(`/complete-application/${user.uid}`);
            } else {
              throw new Error(
                "Looks like you already completed your application. We're getting in touch with you soon."
              );
            }
          } else {
            setError(
              "You are ready to start working with us. Please contact your supervisor."
            );
          }
        }
      } else {
        throw new Error("User data not found. Please contact your supervisor.");
      }
    } catch (error) {
      console.error("Login error:", error);
      // Update this part
      let errorCode = "unknown";
      if (error.code) {
        errorCode = error.code;
      } else if (error.message && error.message.match(/\(([^)]+)\)/)) {
        errorCode = error.message.match(/\(([^)]+)\)/)[1];
      }
      const friendlyMessage = getFriendlyErrorMessage(errorCode);
      setError(friendlyMessage);
    } finally {
      setLoading(false);
    }
  };

  const getFriendlyErrorMessage = (errorCode) => {
    console.log("Error code:", errorCode);
    const errorMessages = {
      "auth/wrong-password": "The password you entered is incorrect. Please try again.",
      "auth/user-not-found": "No account found with this email. Please check your email and try again.",
      "auth/invalid-login-credentials": "Invalid login credentials. Please check your email and password then try again.",
      "auth/invalid-credential": "Invalid login credentials. Please check your email and password then try again.",
      "auth/too-many-requests": "Too many failed attempts. Please wait a few minutes before trying again.",
      "auth/account-disabled": "Your account has been disabled. Please contact your supervisor for assistance.",
    };
    return errorMessages[errorCode] || `An unexpected error occurred (${errorCode}). Please try again later.`;
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-gray-900 text-white overflow-hidden">
      <div className="w-full max-w-md px-4 py-8 sm:px-6 md:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="relative flex flex-col items-center justify-center mb-8"
        >
          <div
            className="relative z-10 text-white text-5xl sm:text-6xl md:text-7xl audiowide mb-2"
            style={{
              textShadow:
                "2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            UNIQUE
          </div>
          <div
            className="relative z-10 text-white text-5xl sm:text-6xl md:text-7xl audiowide"
            style={{
              textShadow:
                "2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            HUB
          </div>
          {/* Brighter blue glow effect */}
          <div
            style={{
              position: "absolute",
              top: "-50%",
              left: "-50%",
              right: "-50%",
              bottom: "-50%",
              background:
                "radial-gradient(circle, rgba(59, 130, 246, 0.3) 0%, rgba(59, 130, 246, 0.2) 40%, transparent 70%)",
              filter: "blur(40px)",
              zIndex: 5,
            }}
          ></div>
          {/* Enhanced shine layer */}
          <div
            style={{
              position: "absolute",
              top: "-25%",
              left: "-25%",
              right: "-25%",
              bottom: "-25%",
              background:
                "conic-gradient(from 180deg at 50% 50%, #38bdf8 0deg, #3b82f6 180deg, transparent 360deg)",
              opacity: 0.2,
              filter: "blur(60px)",
              zIndex: 6,
            }}
          ></div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="flex flex-col items-center"
        >
          {error && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              className="bg-red-900 text-red-100 border-l-4 border-red-500 p-4 mb-4 rounded-r w-full"
              role="alert"
            >
              <p className="font-bold">Error</p>
              <p>{error}</p>
            </motion.div>
          )}
          <form
            className="bg-gray-800 shadow-md rounded px-6 pt-6 pb-8 mb-4 w-full"
            onSubmit={handleLogin}
          >
            <div className="mb-4 relative">
              <input
                className="shadow appearance-none border rounded w-full py-3 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
              />
              <label
                className={`absolute left-3 transition-all duration-300 ${
                  email ? 'text-xs -top-2.5 bg-gray-800 px-1' : 'text-base top-3'
                } text-gray-400 pointer-events-none`}
                htmlFor="email"
              >
                Email
              </label>
            </div>
            <div className="mb-6 relative">
              <input
                className="shadow appearance-none border rounded w-full py-3 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label
                className={`absolute left-3 transition-all duration-300 ${
                  password ? 'text-xs -top-2.5 bg-gray-800 px-1' : 'text-base top-3'
                } text-gray-400 pointer-events-none`}
                htmlFor="password"
              >
                Password
              </label>
            </div>
            <div className="flex items-center justify-between mb-6">
              <button
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
                onClick={() => navigate("/apply")}
                type="button"
              >
                New Employee? Apply Now
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    </main>
  );
}

export default Login;