import React, { useState, useEffect, useMemo } from 'react';
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "@tanstack/react-form";
import {
    collection,
    query,
    orderBy,
    getDocs,
    where
} from "firebase/firestore";
import { STATES } from './constants'; // Import the STATES constant
import { BarcodeScanner } from './BarcodeScanner'; // We'll create this component

async function fetchLocationsData() {
    const q = query(collection(db, "locations"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
}

async function fetchDriversData() {
    const q = query(
        collection(db, "users"),
        where("status", "==", "Active"),
        orderBy("firstName")
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: `${doc.data().firstName} ${doc.data().lastName}`,
        ...doc.data(),
    }));
}

function LicensePlateInput({ value, onChange, typeValue, onTypeChange, stateValue, onStateChange }) {
    const [stateSearch, setStateSearch] = useState('');
    const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);

    const filteredStates = useMemo(() => {
        return STATES.filter(state =>
            state.name.toLowerCase().includes(stateSearch.toLowerCase())
        );
    }, [stateSearch]);

    useEffect(() => {
        if (stateValue) {
            const state = STATES.find(s => s.abbreviation === stateValue);
            if (state) {
                setStateSearch(state.name);
            }
        }
    }, [stateValue]);

    return (
        <div className="flex flex-col items-center">
            <div className="mt-4 bg-yellow-400 border-2 border-black rounded-lg p-4 w-64 h-32 flex items-center justify-center shadow-lg">
                <div className="bg-white w-full h-full flex items-center justify-center border-4 border-black rounded">
                    <span className="text-4xl font-bold tracking-widest">{value}</span>
                </div>
            </div>
            <div className="mt-4">
                <input
                    type="text"
                    value={value}
                    onChange={(e) => {
                        const newValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 8); // Updated to 8
                        onChange(newValue);
                    }}
                    className="text-center uppercase w-64 p-2 border border-gray-300 rounded"
                    maxLength={8}
                    placeholder="Enter plate number"
                />
            </div>
            <div className="mt-4">
                <div className="relative">
                    <input
                        type="text"
                        value={stateSearch}
                        onChange={(e) => setStateSearch(e.target.value)}
                        onFocus={() => setIsStateDropdownOpen(true)}
                        onBlur={() => setTimeout(() => setIsStateDropdownOpen(false), 200)}
                        className="text-center w-64 p-2 border border-gray-300 rounded"
                        placeholder="Search for a state"
                    />
                    {isStateDropdownOpen && filteredStates.length > 0 && (
                        <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {filteredStates.map((state) => (
                                <li
                                    key={state.abbreviation}
                                    className="cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                                    onClick={() => {
                                        onStateChange(state.abbreviation);
                                        setStateSearch(state.name);
                                        setIsStateDropdownOpen(false);
                                    }}
                                >
                                    {state.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

function Accidents() {
    const [step, setStep] = useState(1);
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [showOtherDescription, setShowOtherDescription] = useState(false);
    const [locations, setLocations] = useState([]);
    const [dummyDrivers, setDummyDrivers] = useState([]);
    const [locationSearch, setLocationSearch] = useState('');
    const [driverSearch, setDriverSearch] = useState('');
    const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
    const [isDriverDropdownOpen, setIsDriverDropdownOpen] = useState(false);
    const [licensePlateState, setLicensePlateState] = useState('');
    const [showScanner, setShowScanner] = useState(false);

    // Add new state for accident area
    const [accidentAreas] = useState([
        "QTA", "Ready Stall", "Exit Gate", "Return Area", "Other"
    ]);

    useEffect(() => {
        const updateCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            setCurrentDate(formattedDate);
            setMaxDate(formattedDate); // Set the max date only once
            setCurrentTime(`${hours}:${minutes}`);
        };

        updateCurrentDateTime();
        const intervalId = setInterval(updateCurrentDateTime, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const { data: locationsData, isLoading, error } = useQuery({
        queryKey: ['locations'],
        queryFn: fetchLocationsData
    });

    const { data: driversData, isLoading: isLoadingDrivers, error: driversError } = useQuery({
        queryKey: ['drivers'],
        queryFn: fetchDriversData
    });

    useEffect(() => {
        if (locationsData) {
            setLocations(locationsData);
        }
    }, [locationsData]);

    useEffect(() => {
        if (driversData) {
            setDummyDrivers(driversData);
        }
    }, [driversData]);

    const filteredLocations = useMemo(() => {
        return locations.filter(location =>
            location.name.toLowerCase().includes(locationSearch.toLowerCase())
        );
    }, [locations, locationSearch]);

    const filteredDrivers = useMemo(() => {
        return dummyDrivers
            .filter(driver =>
                driver.name.toLowerCase().includes(driverSearch.toLowerCase())
            )
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [dummyDrivers, driverSearch]);

    const form = useForm({
        defaultValues: {
            location: '',
            accidentArea: '',
            otherAreaDescription: '',
            date: currentDate,
            time: currentTime,
            driver: '',
            licensePlate: '',
            vehicleType: '',
            state: '',
            vin: '',
        },
        onSubmit: async ({ value }) => {
            console.log('Form submitted:', { ...value, state: licensePlateState });
            // Handle form submission
        },
    });

    const handleVINScan = (scannedVIN) => {
        form.setFieldValue('vin', scannedVIN);
        setShowScanner(false);
    };

    if (isLoading || isLoadingDrivers) return <div>Loading...</div>;
    if (error || driversError) return <div>Error: {error?.message || driversError?.message}</div>;

    const nextStep = () => setStep(prev => Math.min(prev + 1, 7));
    const prevStep = () => setStep(prev => Math.max(prev - 1, 1));

    const getSelectedLocationName = () => {
        const selectedLocation = locations.find(loc => loc.id === form.getFieldValue('location'));
        return selectedLocation ? selectedLocation.name : '';
    };

    const getSelectedDriverName = () => {
        const selectedDriver = dummyDrivers.find(driver => driver.id === form.getFieldValue('driver'));
        return selectedDriver ? selectedDriver.name : '';
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-50 to-slate-100 flex items-center justify-center p-4">
            <div className="bg-white shadow-lg rounded-2xl p-8 max-w-md w-full">
                <h1 className="text-3xl font-bold text-slate-800 mb-8 text-center">Report an Accident</h1>
                <form className="space-y-6">
                    {step === 1 && (
                        <div>
                            <label htmlFor="location" className="block text-sm font-medium text-slate-700 mb-2">
                                Location
                            </label>
                            <div className="relative">
                                {form.Field({
                                    name: "location",
                                    children: (field) => (
                                        <>
                                            <input
                                                type="text"
                                                id="location"
                                                {...field.props}
                                                value={locationSearch}
                                                onChange={(e) => {
                                                    setLocationSearch(e.target.value);
                                                    field.handleChange(e.target.value);
                                                }}
                                                onFocus={() => setIsLocationDropdownOpen(true)}
                                                onBlur={() => setTimeout(() => setIsLocationDropdownOpen(false), 200)}
                                                className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out"
                                                placeholder="Search for a location"
                                            />
                                            {isLocationDropdownOpen && filteredLocations.length > 0 && (
                                                <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                    {filteredLocations.map((location) => (
                                                        <li
                                                            key={location.id}
                                                            className="cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                                                            onClick={() => {
                                                                field.handleChange(location.id);
                                                                setLocationSearch(location.name);
                                                                setIsLocationDropdownOpen(false);
                                                            }}
                                                        >
                                                            {location.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {field.state.meta.touchedErrors ? (
                                                <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                            ) : null}
                                        </>
                                    ),
                                })}
                            </div>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="accidentArea" className="block text-sm font-medium text-slate-700 mb-2">
                                    Where in the location did the accident occur?
                                </label>
                                <div className="relative">
                                    {form.Field({
                                        name: "accidentArea",
                                        children: (field) => (
                                            <>
                                                <select
                                                    id="accidentArea"
                                                    {...field.props}
                                                    className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out appearance-none"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        field.handleChange(value);
                                                        setShowOtherDescription(value === "Other");
                                                    }}
                                                >
                                                    <option value="">Select area</option>
                                                    {accidentAreas.map((area) => (
                                                        <option key={area} value={area}>
                                                            {area}
                                                        </option>
                                                    ))}
                                                </select>
                                                {field.state.meta.touchedErrors ? (
                                                    <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                                ) : null}
                                            </>
                                        ),
                                    })}
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            {showOtherDescription && (
                                <div>
                                    <label htmlFor="otherAreaDescription" className="block text-sm font-medium text-slate-700 mb-2">
                                        Please describe the area
                                    </label>
                                    {form.Field({
                                        name: "otherAreaDescription",
                                        children: (field) => (
                                            <>
                                                <textarea
                                                    id="otherAreaDescription"
                                                    {...field.props}
                                                    className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out"
                                                    rows={3}
                                                    placeholder="Describe the area where the accident occurred"
                                                />
                                                {field.state.meta.touchedErrors ? (
                                                    <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                                ) : null}
                                            </>
                                        ),
                                    })}
                                </div>
                            )}
                        </div>
                    )}

                    {step === 3 && (
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="date" className="block text-sm font-medium text-slate-700 mb-2">
                                    Date of Accident
                                </label>
                                {form.Field({
                                    name: "date",
                                    children: (field) => (
                                        <>
                                            <input
                                                type="date"
                                                id="date"
                                                {...field.props}
                                                value={field.state.value}
                                                onChange={(e) => {
                                                    const selectedDate = e.target.value;
                                                    field.handleChange(selectedDate);
                                                    setCurrentDate(selectedDate);
                                                }}
                                                max={maxDate}
                                                className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out"
                                            />
                                            {field.state.meta.touchedErrors ? (
                                                <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                            ) : null}
                                        </>
                                    ),
                                })}
                            </div>
                            <div>
                                <label htmlFor="time" className="block text-sm font-medium text-slate-700 mb-2">
                                    Time of Accident
                                </label>
                                {form.Field({
                                    name: "time",
                                    children: (field) => (
                                        <>
                                            <input
                                                type="time"
                                                id="time"
                                                {...field.props}
                                                value={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    setCurrentTime(e.target.value);
                                                }}
                                                className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out"
                                            />
                                            {field.state.meta.touchedErrors ? (
                                                <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                            ) : null}
                                        </>
                                    ),
                                })}
                            </div>
                        </div>
                    )}

                    {step === 4 && (
                        <div>
                            <label htmlFor="driver" className="block text-sm font-medium text-slate-700 mb-2">
                                Driver
                            </label>
                            <div className="relative">
                                {form.Field({
                                    name: "driver",
                                    children: (field) => (
                                        <>
                                            <input
                                                type="text"
                                                id="driver"
                                                {...field.props}
                                                value={driverSearch}
                                                onChange={(e) => setDriverSearch(e.target.value)}
                                                onFocus={() => setIsDriverDropdownOpen(true)}
                                                onBlur={() => setTimeout(() => setIsDriverDropdownOpen(false), 200)}
                                                className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out"
                                                placeholder="Search for a driver"
                                            />
                                            {isDriverDropdownOpen && filteredDrivers.length > 0 && (
                                                <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                    {filteredDrivers.map((driver) => (
                                                        <li
                                                            key={driver.id}
                                                            className="cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                                                            onClick={() => {
                                                                field.handleChange(driver.id);
                                                                setDriverSearch(driver.name);
                                                                setIsDriverDropdownOpen(false);
                                                            }}
                                                        >
                                                            {driver.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {field.state.meta.touchedErrors ? (
                                                <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                            ) : null}
                                        </>
                                    ),
                                })}
                            </div>
                        </div>
                    )}

                    {step === 5 && (
                        <div>
                            <label htmlFor="licensePlate" className="block text-sm font-medium text-slate-700 mb-2">
                                License Plate of Damaged Vehicle
                            </label>
                            {form.Field({
                                name: "licensePlate",
                                children: (field) => (
                                    <>
                                        <LicensePlateInput
                                            value={field.state.value}
                                            onChange={(value) => field.handleChange(value)}
                                            typeValue={form.getFieldValue('vehicleType')}
                                            onTypeChange={(value) => form.setFieldValue('vehicleType', value)}
                                            stateValue={licensePlateState}
                                            onStateChange={setLicensePlateState}
                                        />
                                        {field.state.meta.touchedErrors ? (
                                            <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                        ) : null}
                                    </>
                                ),
                            })}
                        </div>
                    )}

                    {step === 6 && (
                        <div>
                            <label htmlFor="vin" className="block text-sm font-medium text-slate-700 mb-2">
                                Vehicle Identification Number (VIN)
                            </label>
                            {form.Field({
                                name: "vin",
                                children: (field) => (
                                    <>
                                        <div className="space-y-4">
                                            <button
                                                type="button"
                                                onClick={() => setShowScanner(true)}
                                                className="w-full py-2 px-4 border border-slate-300 rounded-lg shadow-sm text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                                            >
                                                Scan VIN
                                            </button>
                                            <input
                                                type="text"
                                                id="vin"
                                                {...field.props}
                                                className="block w-full pl-4 pr-10 py-3 text-base border border-slate-300 text-slate-700 bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg transition duration-150 ease-in-out"
                                                placeholder="Enter VIN manually"
                                            />
                                        </div>
                                        {showScanner && (
                                            <BarcodeScanner
                                                onScan={handleVINScan}
                                                onClose={() => setShowScanner(false)}
                                            />
                                        )}
                                        {field.state.meta.touchedErrors ? (
                                            <div className="text-red-500 text-sm mt-1">{field.state.meta.touchedErrors}</div>
                                        ) : null}
                                    </>
                                ),
                            })}
                        </div>
                    )}

                    {step === 7 && (
                        <div className="space-y-4">
                            <h2 className="text-xl font-semibold text-slate-700">Review Your Report</h2>
                            <div>
                                <p className="font-medium">Location:</p>
                                <p>{getSelectedLocationName()}</p>
                            </div>
                            <div>
                                <p className="font-medium">Accident Area:</p>
                                <p>{form.getFieldValue('accidentArea')}</p>
                                {form.getFieldValue('accidentArea') === 'Other' && (
                                    <p>{form.getFieldValue('otherAreaDescription')}</p>
                                )}
                            </div>
                            <div>
                                <p className="font-medium">Date of Accident:</p>
                                <p>{form.getFieldValue('date')}</p>
                            </div>
                            <div>
                                <p className="font-medium">Time of Accident:</p>
                                <p>{form.getFieldValue('time')}</p>
                            </div>
                            <div>
                                <p className="font-medium">Driver:</p>
                                <p>{getSelectedDriverName()}</p>
                            </div>
                            <div>
                                <p className="font-medium">License Plate:</p>
                                <p>{form.getFieldValue('licensePlate')}</p>
                            </div>
                            <div>
                                <p className="font-medium">License Plate State:</p>
                                <p>{licensePlateState}</p>
                            </div>
                            <div>
                                <p className="font-medium">VIN:</p>
                                <p>{form.getFieldValue('vin')}</p>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-between">
                        {step > 1 && (
                            <button
                                type="button"
                                onClick={prevStep}
                                className="py-2 px-4 border border-slate-300 rounded-lg shadow-sm text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                            >
                                Previous
                            </button>
                        )}
                        {step < 7 ? (
                            <button
                                type="button"
                                onClick={nextStep}
                                className="ml-auto py-2 px-4 border-0 rounded-lg shadow-sm text-base font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                                style={{backgroundColor: '#475569'}} 
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={form.handleSubmit}
                                className="ml-auto py-2 px-4 border-0 rounded-lg shadow-sm text-base font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                                style={{backgroundColor: '#475569'}} 
                            >
                                Submit Report
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Accidents;