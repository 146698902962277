import React, { useEffect, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

export function BarcodeScanner({ onScan, onClose }) {
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');

    useEffect(() => {
        const codeReader = new BrowserMultiFormatReader();

        codeReader.listVideoInputDevices()
            .then((videoInputDevices) => {
                // Filter out front-facing cameras
                const backCameras = videoInputDevices.filter(device => 
                    !/front/i.test(device.label)
                );
                setCameras(backCameras);
                if (backCameras.length > 0) {
                    setSelectedCamera(backCameras[0].deviceId);
                }
            })
            .catch((err) => {
                console.error('Failed to list video devices', err);
            });

        return () => {
            codeReader.reset();
        };
    }, []);

    useEffect(() => {
        if (!selectedCamera) return;

        const codeReader = new BrowserMultiFormatReader();
        
        codeReader.decodeFromVideoDevice(selectedCamera, 'video', (result, err) => {
            if (result) {
                onScan(result.getText());
                codeReader.reset(); // Stop scanning after successful read
            }
            if (err && !(err instanceof NotFoundException)) {
                console.error('Scan error', err);
            }
        }).catch(err => {
            console.error('Failed to start scanning', err);
        });

        return () => {
            codeReader.reset();
        };
    }, [selectedCamera, onScan]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg">
                <video id="video" className="w-full max-w-sm"></video>
                {cameras.length > 1 && (
                    <select 
                        value={selectedCamera} 
                        onChange={(e) => setSelectedCamera(e.target.value)}
                        className="mt-2 w-full p-2 border border-gray-300 rounded"
                    >
                        {cameras.map((camera) => (
                            <option key={camera.deviceId} value={camera.deviceId}>
                                {camera.label || `Camera ${camera.deviceId}`}
                            </option>
                        ))}
                    </select>
                )}
                <button
                    onClick={onClose}
                    className="mt-4 w-full py-2 px-4 border border-slate-300 rounded-lg shadow-sm text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}