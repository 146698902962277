import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import { collection, query, where, getDocs } from "firebase/firestore";

const Reports = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("All Positions");
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [showApprovedOnly, setShowApprovedOnly] = useState(true);

  const convertHoursToHM = useCallback((hours) => {
    const totalMinutes = hours * 60;
    const hoursPart = Math.floor(totalMinutes / 60);
    const minutesPart = Math.floor(totalMinutes % 60);
    return `${hoursPart}:${minutesPart.toString().padStart(2, "0")}`;
  }, []);

  const fetchShiftsData = async ({ queryKey }) => {
    const {
      startDate,
      endDate,
      selectedLocation,
      selectedPosition,
      showApprovedOnly,
    } = queryKey[1];
    const start = startDate ? new Date(startDate + "T00:00:00") : null;
    const end = endDate ? new Date(endDate + "T23:59:59") : null;

    let queryConditions = [collection(db, "shifts")];

    if (selectedLocation && selectedLocation !== "All Locations") {
      queryConditions.push(where("locationName", "==", selectedLocation));
    }

    if (selectedPosition && selectedPosition !== "All Positions") {
      queryConditions.push(
        where("roleChanges", "array-contains", { role: selectedPosition })
      );
    }

    if (showApprovedOnly) {
      queryConditions.push(where("approvedBy", "!=", null));
    }

    if (start) {
      queryConditions.push(where("startTimestamp", ">=", start));
    }

    if (end) {
      queryConditions.push(where("startTimestamp", "<=", end));
    }

    let shiftsQuery = query(...queryConditions);
    const querySnapshot = await getDocs(shiftsQuery);
    let shiftsData = {};

    const userDataPromises = querySnapshot.docs.map(async (doc) => {
      const data = doc.data();

      try {
        const userDoc = await getDocs(
          query(collection(db, "users"), where("uid", "==", data.userId))
        );
        const userRate = userDoc.empty
          ? 0
          : parseFloat(userDoc.docs[0].data().rate) || 0;
        const roleHours = calculateRoleHours(
          data.startTimestamp,
          data.endTimestamp,
          data.roleChanges,
          data.breakTimestamps
        );

        return {
          ...data,
          userRate,
          roleHours,
          companyName: userDoc.docs[0]?.data().companyName || "",
        };
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    });

    const results = await Promise.all(userDataPromises);

    results.forEach((result) => {
      if (!result) return;

      const employeeKey = result.userId || "missing-userId";
      if (!shiftsData[employeeKey]) {
        shiftsData[employeeKey] = {
          userId: employeeKey,
          firstName: result.firstName || "Unknown",
          lastName: result.lastName || "Unknown",
          companyName: result.companyName || "",
          positions: {},
          locations: {},
          method: new Set(),
          hours: 0,
          rate: result.userRate,
          total: 0,
        };
      }

      Object.entries(result.roleHours).forEach(([role, hours]) => {
        if (!shiftsData[employeeKey].positions[role]) {
          shiftsData[employeeKey].positions[role] = 0;
        }
        shiftsData[employeeKey].positions[role] += hours;

        if (!shiftsData[employeeKey].locations[result.locationName]) {
          shiftsData[employeeKey].locations[result.locationName] = {
            total: 0,
            roles: {},
          };
        }
        shiftsData[employeeKey].locations[result.locationName].total += hours;
        shiftsData[employeeKey].locations[result.locationName].roles[role] =
          (shiftsData[employeeKey].locations[result.locationName].roles[role] ||
            0) + hours;

        shiftsData[employeeKey].hours += hours;
        shiftsData[employeeKey].total += hours * result.userRate;
      });

      shiftsData[employeeKey].method.add(result.payMethod);
    });

    let filteredShiftsData = Object.values(shiftsData);

    const maxHours = Math.max(...filteredShiftsData.map((emp) => emp.hours), 0);
    const minHours = Math.min(...filteredShiftsData.map((emp) => emp.hours), 0);
    setMaxHours(maxHours);
    setMinHours(minHours);
    setSliderValue(minHours);

    return filteredShiftsData.map((employee) => ({
      ...employee,
      positions: Object.entries(employee.positions)
        .map(([role, hours]) => `${role}: ${convertHoursToHM(hours)}`)
        .join(", "),
      locations: Object.entries(employee.locations)
        .map(([location]) => location)
        .join(", "),
      hours: Number(employee.hours),
      total: Number(employee.total),
      // Add these new properties for detailed filtering
      positionsDetail: employee.positions,
      locationsDetail: employee.locations,
    }));
  };

  const calculateRoleHours = useCallback(
    (startTimestamp, endTimestamp, roleChanges, breakTimestamps) => {
      const start = startTimestamp ? startTimestamp.toDate() : null;
      const end = endTimestamp ? endTimestamp.toDate() : new Date();
      if (!start) return {};

      const rolesDuration = {};
      let previousTimestamp = start;

      roleChanges.forEach((roleChange, index) => {
        const currentRole = roleChange.role;
        const nextTimestamp =
          index < roleChanges.length - 1
            ? roleChanges[index + 1].timestamp.toDate()
            : end;

        let roleDuration = nextTimestamp - previousTimestamp;

        if (breakTimestamps && breakTimestamps.length > 1) {
          for (let i = 0; i < breakTimestamps.length; i += 2) {
            const breakStart = new Date(breakTimestamps[i]);
            const breakEnd = breakTimestamps[i + 1]
              ? new Date(breakTimestamps[i + 1])
              : new Date();
            if (breakStart >= previousTimestamp && breakEnd <= nextTimestamp) {
              roleDuration -= breakEnd - breakStart;
            }
          }
        }

        const roleHours = roleDuration / (1000 * 60 * 60);
        rolesDuration[currentRole] =
          (rolesDuration[currentRole] || 0) + roleHours;

        previousTimestamp = nextTimestamp;
      });

      return rolesDuration;
    },
    []
  );

  const getDefaultDateRange = useCallback(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - dayOfWeek - 7);
    const lastSaturday = new Date(today);
    lastSaturday.setDate(today.getDate() - dayOfWeek - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(lastSunday),
      endDate: formatDate(lastSaturday),
    };
  }, []);

  useEffect(() => {
    const { startDate, endDate } = getDefaultDateRange();
    setStartDate(startDate);
    setEndDate(endDate);
  }, [getDefaultDateRange]);

  const { data: allEmployees = [], isLoading } = useQuery({
    queryKey: [
      "shifts",
      {
        startDate,
        endDate,
        showApprovedOnly,
      },
    ],
    queryFn: fetchShiftsData,
    enabled: !!startDate && !!endDate,
  });

  const filteredEmployees = useMemo(() => {
    return allEmployees
      .map((employee) => {
        if (selectedLocation === "All Locations") {
          return employee;
        }

        const locationData = employee.locationsDetail[selectedLocation];
        if (!locationData) {
          return null;
        }

        const hours = locationData.total;
        const positionsForLocation = Object.entries(locationData.roles)
          .map(([role, roleHours]) => `${role}: ${convertHoursToHM(roleHours)}`)
          .join(", ");

        return {
          ...employee,
          positions: positionsForLocation,
          locations: selectedLocation,
          hours: hours,
          total: hours * employee.rate,
        };
      })
      .filter(Boolean);
  }, [allEmployees, selectedLocation, convertHoursToHM]);

  useEffect(() => {
    const fetchLocationsAndPositions = async () => {
      if (!startDate || !endDate) {
        console.error("Invalid startDate or endDate");
        return;
      }

      const start = new Date(`${startDate}T00:00:00`);
      const end = new Date(`${endDate}T23:59:59`);

      if (isNaN(start) || isNaN(end)) {
        console.error("Invalid start or end date");
        return;
      }

      const shiftsRef = collection(db, "shifts");
      const queryConstraints = [
        where("startTimestamp", ">=", start),
        where("startTimestamp", "<=", end),
      ];

      const shiftsQuery = query(shiftsRef, ...queryConstraints);
      const querySnapshot = await getDocs(shiftsQuery);

      const newLocations = new Set();
      const newPositions = new Set();

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { locationName, roleChanges } = data;

        if (locationName) {
          newLocations.add(locationName);
        }

        roleChanges.forEach(({ role }) => {
          if (role) {
            newPositions.add(role);
          }
        });
      });

      setLocations(Array.from(newLocations).sort());
      setPositions(Array.from(newPositions));
    };

    if (startDate && endDate) {
      fetchLocationsAndPositions().catch(console.error);
    }
  }, [startDate, endDate]);

  const toggleLocationDropdown = useCallback(() => {
    setLocationDropdownOpen((prev) => !prev);
    setPositionDropdownOpen(false);
  }, []);

  const exportToExcel = useCallback(() => {
    const dataToExport = filteredEmployees.filter(
      (emp) => emp.hours >= sliderValue
    );

    const wb = XLSX.utils.book_new();
    const wsHeader = [
      ["Name", "Position", "Location", "Hours", "Method", "Rate", "Total ($)"],
    ];

    const wsData = dataToExport.map((emp) => [
      emp.companyName || `${emp.lastName}, ${emp.firstName}`,
      emp.positions,
      emp.locations,
      convertHoursToHM(emp.hours),
      Array.from(emp.method).join(", "),
      `$${emp.rate.toFixed(2)}`,
      `$${emp.total.toFixed(2)}`,
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));
    XLSX.utils.book_append_sheet(wb, ws, "Report");

    const filename = new Date()
      .toLocaleString("default", {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(/[\s,:/]+/g, "_");

    XLSX.writeFile(wb, `export_${filename}.xls`);
  }, [filteredEmployees, sliderValue, convertHoursToHM]);

  // Add this new useEffect to update min and max hours when filteredEmployees changes
  useEffect(() => {
    if (filteredEmployees.length > 0) {
      const hours = filteredEmployees.map((emp) => emp.hours);
      setMaxHours(Math.max(...hours));
      setMinHours(Math.min(...hours));
      setSliderValue(Math.min(...hours)); // Reset slider to minimum value
    } else {
      setMaxHours(0);
      setMinHours(0);
      setSliderValue(0);
    }
  }, [filteredEmployees]);

  // Update the renderReports useMemo to use filteredEmployees for displaying data
  const renderReports = useMemo(() => {
    const dataToDisplay = filteredEmployees.filter(
      (emp) => emp.hours >= sliderValue
    );

    return (
      <div className="p-8 bg-[#1F2937] min-h-screen text-white">
        <h1 className="text-2xl font-bold mb-6">Reports</h1>
        <div className="flex flex-nowrap justify-between items-center w-full mx-auto bg-[#d6e7ff] p-6 rounded-lg shadow-md mb-4 text-black">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="mr-2"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="mr-2"
          />
          <div className="flex items-center space-x-2">
            <button
              id="dropdownDefaultButton"
              className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
              type="button"
              onClick={toggleLocationDropdown}
            >
              {selectedLocation || "Select Location"}
              <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 20 20">
                <path
                  d="M5.5 7L10 11.5L14.5 7"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {locationDropdownOpen && (
              <div
                id="dropdown"
                className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute mt-1 overflow-auto max-h-60"
              >
                <ul
                  className="py-1 text-sm text-gray-700"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <button
                      className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                      onClick={() => {
                        setSelectedLocation("All Locations");
                        setLocationDropdownOpen(false);
                      }}
                    >
                      All Locations
                    </button>
                  </li>
                  {locations.map((location) => (
                    <li key={location}>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        onClick={() => {
                          setSelectedLocation(location);
                          setLocationDropdownOpen(false);
                        }}
                      >
                        {location}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="relative">
              <button
                id="dropdownPositionButton"
                className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
                type="button"
                onClick={() => setPositionDropdownOpen((prev) => !prev)}
              >
                {selectedPosition || "Select Position"}
                <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 20 20">
                  <path
                    d="M5.5 7L10 11.5L14.5 7"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {positionDropdownOpen && (
                <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute">
                  <ul className="py-1 text-sm text-gray-700">
                    <li>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        onClick={() => {
                          setSelectedPosition("All Positions");
                          setPositionDropdownOpen(false);
                        }}
                      >
                        All Positions
                      </button>
                    </li>
                    {positions.map((position) => (
                      <li key={position}>
                        <button
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                          onClick={() => {
                            setSelectedPosition(position);
                            setPositionDropdownOpen(false);
                          }}
                        >
                          {position}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="range"
                min={minHours}
                max={maxHours}
                value={sliderValue}
                onChange={(e) => setSliderValue(Number(e.target.value))}
                className="slider"
              />
              <span>{convertHoursToHM(sliderValue)}</span>
            </div>
          </div>
          <label className="flex items-center space-x-1 bg-white px-2 py-1 rounded-lg">
            <input
              type="checkbox"
              checked={showApprovedOnly}
              onChange={(e) => setShowApprovedOnly(e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="text-xs font-medium text-gray-700">Approved</span>
          </label>
          <button
            onClick={exportToExcel}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
          </button>
        </div>

        {isLoading ? (
          <div className="text-center mt-8">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <table className="min-w-full bg-white rounded-lg">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="px-4 py-2 rounded-tl-lg">Name</th>
                <th className="px-4 py-2">Position</th>
                <th className="px-4 py-2">Location</th>
                <th className="px-4 py-2">Total Hours</th>
                <th className="px-4 py-2">Method</th>
                <th className="px-4 py-2">Rate</th>
                <th className="px-4 py-2 rounded-tr-lg">Total</th>
              </tr>
            </thead>

            <tbody className="text-gray-700">
              {dataToDisplay.map((employee, index) => (
                <tr key={employee.userId}>
                  <td className="border px-4 py-2">
                    {employee.companyName ||
                      `${employee.lastName}, ${employee.firstName}`}
                  </td>
                  <td className="border px-4 py-2">{employee.positions}</td>
                  <td className="border px-4 py-2">{employee.locations}</td>
                  <td className="border px-4 py-2">
                    {convertHoursToHM(employee.hours)}
                  </td>
                  <td className="border px-4 py-2">
                    {Array.from(employee.method).join(", ")}
                  </td>
                  <td className="border px-4 py-2">${employee.rate}</td>
                  <td className="border px-4 py-2">
                    ${employee.total.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }, [
    filteredEmployees,
    startDate,
    endDate,
    toggleLocationDropdown,
    selectedLocation,
    locationDropdownOpen,
    locations,
    selectedPosition,
    positionDropdownOpen,
    positions,
    minHours,
    maxHours,
    sliderValue,
    showApprovedOnly,
    exportToExcel,
    isLoading,
    convertHoursToHM,
  ]);

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      {renderReports}
    </div>
  );
};

export default Reports;